<template>
  <a-modal class="taskModal" width="1048px" :visible="showDialog" :body-style="{padding: 0}" :maskClosable="false"
           :footer="false"
           title="产品详情"
           @cancel="closeDialog">
    <template #title>
      <div class="modal-title">
        <img :src="$toUrl('renwu.png')" alt="">
        #{{ productDetail.seqid }}
      </div>
    </template>
    <div class="modal-body" v-loading="isLoading">
      <div class="body-top">
        <div class="title">
          <edp-input
              v-model="productDetail.product_name"
              @change="$refs.bizlog.getBizLogList()"
              :db="dbs.proCore"
              field-name="product_name"
              :oid="productDetail.oid"
              :pro-oid="productDetail.oid"
              size="big">
          </edp-input>
        </div>
        <div class="detail">
          <div class="detail-item" style="width: 130px;margin-right: 5px;">
            <img v-if="productDetail.creator_oid_pic!==''" :src="productDetail.creator_oid_pic" class="round" alt="">
            <span v-else class="round"></span>
            <div class="right">
              <edp-select
                  style="width: 90px;"
                  size="mini"
                  showSearch
                  v-model="productDetail.product_admin"
                  @change="$refs.bizlog.getBizLogList()"
                  :db="dbs.proCore"
                  field-name="product_admin"
                  :oid="productDetail.oid"
                  :pro-oid="productDetail.oid"
                  cat-type="product_admin"
                  account="all"
              />
              <div class="label">负责人</div>
            </div>
          </div>
          <div class="detail-item">
            <div class="category-img">
              <img style="width: 20px;height: 20px;" :src="$toUrl('category.png')" alt="">
            </div>
            <div class="right">
              <category-tree-select @change="$refs.bizlog.getBizLogList()" v-model="productDetail.product_category" :pro-oid="productDetail.oid" :oid="productDetail.oid" />
              <div class="label">产品类别</div>
            </div>
          </div>
        </div>
      </div>
      <div class="body-content">
        <div class="left left-tabs">
          <a-tabs v-model="leftTab" @change="leftTabChange">
            <a-tab-pane key="1" tab="详情"></a-tab-pane>
            <a-tab-pane key="2" tab="BOM"></a-tab-pane>
            <a-tab-pane key="3" tab="库存"></a-tab-pane>
            <a-tab-pane key="4" tab="应用项目"></a-tab-pane>
          </a-tabs>
          <div class="tab-item active-1" v-show="leftTab === '1'">
            <div class="title" style="margin-top: 20px;margin-bottom: 11px;">产品信息</div>
            <div class="form-list" style="grid-row-gap:0">
              <div class="form-item">
                <div class="label">产品名称</div>
                <edp-input
                    v-model="productDetail.product_name"
                    @change="$refs.bizlog.getBizLogList()"
                    :db="dbs.proCore"
                    field-name="product_name"
                    :oid="productDetail.oid"
                    :pro-oid="productDetail.oid"
                ></edp-input>
              </div>
              <div class="form-item">
                <div class="label">产品编号</div>
                <edp-input
                    v-model="productDetail.product_code"
                    @change="$refs.bizlog.getBizLogList()"
                    :db="dbs.proCore"
                    field-name="product_code"
                    :pro-oid="productDetail.oid"
                    :oid="productDetail.oid"
                >
                </edp-input>
              </div>
              <div class="form-item">
                <div class="label">规格型号</div>
                <edp-input
                    v-model="productDetail.tech_info"
                    @change="$refs.bizlog.getBizLogList()"
                    :db="dbs.proCore"
                    field-name="tech_info"
                    :pro-oid="productDetail.oid"
                    :oid="productDetail.oid"
                >
                </edp-input>
              </div>
              <div class="form-item">
                <div class="label">产品版本</div>
                <edp-input
                    v-model="productDetail.product_series"
                    @change="$refs.bizlog.getBizLogList()"
                    :db="dbs.proCore"
                    field-name="product_series"
                    :pro-oid="productDetail.oid"
                    :oid="productDetail.oid"
                >
                </edp-input>
              </div>
              <div class="form-item">
                <div class="label">产品类型</div>
                <edp-select
                    showSearch
                    v-model="productDetail.product_type"
                    @change="$refs.bizlog.getBizLogList()"
                    :db="dbs.proCore"
                    field-name="product_type"
                    :pro-oid="productDetail.oid"
                    :oid="productDetail.oid"
                    cat-type="product_type"
                >
                </edp-select>
              </div>
              <div class="form-item">
                <div class="label">品牌</div>
                <edp-input
                    v-model="productDetail.band"
                    @change="$refs.bizlog.getBizLogList()"
                    :db="dbs.proCore"
                    field-name="band"
                    :pro-oid="productDetail.oid"
                    :oid="productDetail.oid"
                >
                </edp-input>
              </div>
              <div class="form-item">
                <div class="label">供应商</div>
                <edp-select
                    showSearch
                    ref="companyName"
                    v-model="productDetail.company_oid"
                    :db="dbs.proCore"
                    field-name="company_oid"
                    :pro-oid="productDetail.oid"
                    :oid="productDetail.oid"
                    :options="companyOptions"
                    @change="() => {
                      this.getProductDetails(prooid)
                      this.$refs.bizlog.getBizLogList()
                    }"
                >
                </edp-select>
              </div>
              <div class="form-item">
                <div class="label">供应商编号</div>
                <auto-input
                    popperClass="edp-input"
                    url="/company/retrieve"
                    value-key="company_no"
                    label-key="company_no"
                    v-model="productDetail.company_no"
                    :label.sync="productDetail.company_no"
                    @change="(value, item) => {
                      this.$refs.companyName.setValue(item.oid)
                      this.$refs.bizlog.getBizLogList()
                    }"
                />
                <!--<edp-input-->
                <!--    v-model="productDetail.product_code"-->
                <!--    :db="dbs.proCore"-->
                <!--    field-name="product_code"-->
                <!--    :pro-oid="productDetail.oid"-->
                <!--    :oid="productDetail.oid"-->
                <!--&gt;-->
                <!--</edp-input>-->
              </div>
              <div class="form-item">
                <div class="label">原厂家</div>
                <edp-select
                    showSearch
                    v-model="productDetail.product_company"
                    @change="$refs.bizlog.getBizLogList()"
                    :db="dbs.proCore"
                    field-name="product_company"
                    :pro-oid="productDetail.oid"
                    :oid="productDetail.oid"
                    :options="companyOptions"
                >
                </edp-select>
              </div>
            </div>
            <div class="line"></div>
            <div class="title" style="margin: 20px 0">产品说明</div>
            <div class="form-list" style="grid-template-columns: 1fr">
              <edp-textarea
                  v-model="productDetail.product_intro"
                  @change="$refs.bizlog.getBizLogList()"
                  :db="dbs.proCore"
                  field-name="product_intro"
                  :pro-oid="productDetail.oid"
                  :oid="productDetail.oid"
              ></edp-textarea>
            </div>
          </div>
          <div class="tab-item active-2" v-show="leftTab === '2'">
            <a-table v-if="bomData.length"
                     tableLayout="fixed"
                     :columns="bomColumns"
                     :data-source="bomData"
                     :expandIcon="expandIcon"
                     rowKey="oid" :defaultExpandAllRows="true"
                     :expandIconColumnIndex="1"
                     :pagination="false"
            >
              <span slot="id" slot-scope="text, record">#{{ record.seqid }}</span>
              <template slot="product_code" slot-scope="text, record">
                <a-tooltip>
                  <template slot="title">{{ record.product_code }}</template>
                  <span class="ellipsis-text" style="width: 70px;display: inline-block">{{ record.product_code }}</span>
                </a-tooltip>
              </template>
              <template slot="company_name" slot-scope="text, record">
                <a-tooltip>
                  <template slot="title">{{ record.company_name }}</template>
                  <span class="ellipsis-text" style="width: 150px;display: inline-block">{{
                      record.company_name
                    }}</span>
                </a-tooltip>
              </template>
            </a-table>
          </div>
          <div class="tab-item active-3" v-show="leftTab === '3'">
            <div class="title" style="margin-top: 20px;margin-bottom: 8px;">库存信息</div>
            <div class="form-list" style="margin-bottom: 9px;">
              <div class="form-item">
                <div class="label" style="width: 110px;">安全库存</div>
                <edp-input
                    style="width: 100%"
                    size="mini"
                    v-model="inventory.safe_count"
                    @change="$refs.bizlog.getBizLogList()"
                    :db="dbs.prjIssue"
                    field-name="safe_count"
                    :oid="oid"
                    :pro-oid="prooid"
                >
                </edp-input>
              </div>
              <div class="form-item">
                <div class="label" style="width: 110px;">库存数量</div>
                <edp-input
                    style="width: 100%"
                    size="mini"
                    v-model="inventory.store_count"
                    @change="$refs.bizlog.getBizLogList()"
                    :db="dbs.prjIssue"
                    field-name="store_count"
                    :oid="oid"
                    :pro-oid="prooid"
                >
                </edp-input>
              </div>
            </div>
            <div class="line"></div>
            <div class="title" style="margin-top: 20px;">库存明细</div>
            <div class="inventoryDetails" v-if="inventoryDetails.length!==0">
              <div class="item" v-for="item in inventoryDetails" :key="item.oid">
                <div class="top">
                  <div class="left">
                    <div class="icon out" v-if="item.business_status==='0'">出</div>
                    <div class="icon enter" v-else>入</div>
                    <div class="name">{{ item.editor }}</div>
                    <div class="time">{{ dayjs(item.create_time).format('YYYY-MM-DD') }}</div>
                    <a-tooltip>
                      <template slot="title" v-if="item.project_name!==''">{{ item.project_name }}</template>
                      <div class="project ellipsis-text">所属项目：{{ item.project_name }}</div>
                    </a-tooltip>
                  </div>
                  <div class="right out" v-if="item.business_status==='0'">-{{
                      Number(item.change_count).toFixed(2)
                    }}
                  </div>
                  <div class="right enter" v-else>+{{ Number(item.change_count).toFixed(2) }}</div>
                </div>
                <div class="bottom">
                  <div class="bottom-item" v-if="item.buyno">采购编号：{{ item.buyno }}</div>
                  <div class="bottom-item">{{ item.remark }}</div>
                </div>
              </div>
              <!--<div class="item">-->
              <!--  <div class="top">-->
              <!--    <div class="left">-->
              <!--      <div class="icon enter">入</div>-->
              <!--      <div class="name">张三</div>-->
              <!--      <div class="time">08月07日 16:28</div>-->
              <!--      <div class="project">所属项目：特斯拉充电桩研发项目</div>-->
              <!--    </div>-->
              <!--    <div class="right">-8,718,741</div>-->
              <!--  </div>-->
              <!--  <div class="bottom">-->
              <!--    <div class="bottom-item">采购单号：778899</div>-->
              <!--    <div class="bottom-item">2022年12月15日 交付给苹果集团8718741件</div>-->
              <!--  </div>-->
              <!--</div>-->
            </div>
            <div v-else style="margin-top: 20px">
              <edp-empty></edp-empty>
            </div>
          </div>
          <div class="tab-item active-4" v-show="leftTab === '4'">
            <a-table tableLayout="fixed" :columns="projectColumns" :data-source="projectData" rowKey="oid"
                     :pagination="false">
              <span slot="status" slot-scope="text, record" class="status" :class="{
                blue:text==='0'||text==='700',
                green:text==='500',
                yellow:text==='100',
                gray:text==='1000',
              }">
                 {{ record.business_status_name }}
              </span>

              <span
                  slot="project_name"
                  slot-scope="text, record"
                  style="color: var(--primary-color);cursor: pointer"
                  @click="toggleProjectHistory(record)"
              >
                {{ record.project_name }}
              </span>
            </a-table>
          </div>
        </div>
        <div class="right">
          <div class="title">动态</div>
          <div class="tab">
            <a-tabs v-model="rightTab">
              <a-tab-pane key="1" tab="详情"></a-tab-pane>
            </a-tabs>
            <div class="y-scroll">
              <div class="active-1" v-show="rightTab === '1'">
                <bizlog ref="bizlog" :source-oid="sourceOid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import EdpInput from "@/components/e-form/edp-input.vue";
import EdpSelect from "@/components/e-form/edp-select.vue";
import { FieldUpdateDb } from "@/utils/FieldUpdate";
import EdpTextarea from "@/components/e-form/edp-textarea.vue";
import { fetch } from "@/utils/request";
import Bizlog from "@/components/bizlog.vue";
import autoInput from "@/components/autoInput.vue";
import dayjs from "dayjs";
import CategoryTreeSelect from "@/view/product/components/categoryTreeSelect.vue";

const bomColumns = [
  { title: 'ID', dataIndex: 'seqid', width: 60, scopedSlots: { customRender: 'id' } },
  { title: '产品名称', dataIndex: 'node_name' },
  { title: '产品编号', dataIndex: 'product_code', width: 80, scopedSlots: { customRender: 'product_code' } },
  { title: '供应商', dataIndex: 'company_name', width: 150, scopedSlots: { customRender: 'company_name' } },
  { title: '供应商编号', dataIndex: 'company_no', width: 120 },
  { title: 'BOM', dataIndex: 'require_count', width: 80 }
]
const projectColumns = [
  { title: '项目编号', dataIndex: 'project_code', width: 120 },
  { title: '项目名称', dataIndex: 'project_name', scopedSlots: { customRender: 'project_name' } },
  { title: '项目状态', dataIndex: 'business_status', scopedSlots: { customRender: 'status' }, width: 120 },
  { title: '计划交付', dataIndex: 'schedule_count', width: 120 },
  { title: '已经交付', dataIndex: 'actual_count', width: 120 }
]
export default {
  computed: {
    dayjs() {
      return dayjs
    }
  },
  components: { CategoryTreeSelect, autoInput, Bizlog, EdpTextarea, EdpSelect, EdpInput },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    prooid: {
      type: String,
    }
  },
  data() {
    return {
      dbs: {
        prjIssue: new FieldUpdateDb("/whs/update/onefield", "whs_core"),
        proCore: new FieldUpdateDb("pro", "pro_core"),
      },
      sourceOid: '',
      // 产品详情
      productDetail: {},
      // 供应商
      companyOptions: [],
      inventory: {
        // 库存数量
        store_count: '',
        // 安全数量
        safe_count: ''
      },
      // 库存oid
      oid: '',
      // 库存明细
      inventoryDetails: [],
      leftTab: "1",
      rightTab: "1",
      bomColumns,
      projectColumns,
      bomData: [],
      // 应用项目数据
      projectData: [],
      // 加载中
      isLoading: true
    }
  },
  created() {
    this.getCompanyList()
  },
  watch: {
    prooid: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.sourceOid = newValue
      }
    }
  },
  methods: {
    // 获取页面数据
    async getPageData(prooid) {
      this.isLoading = true
      try {
        const promises = [
          this.getProductDetails(prooid),
          this.getBomData(prooid),
          this.getInventoryDetails(prooid),
          this.getInventoryInformation(prooid),
          this.getApplicationProjectData(prooid)
        ]
        await Promise.all(promises)
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    // 获取产品详情
    async getProductDetails(prooid) {
      const res = await fetch('post', '/pro/info', { oid: prooid })
      if (res.status === 0) {
        this.productDetail = res.data
      } else {
        this.$message.error(res.message)
      }
    },

    // 获取供应商
    getCompanyList() {
      fetch('post', '/company/retrieve', { keywords: '', page_size: 999 }).then((res) => {
        if (res.status === 0) {
          this.companyOptions = res.data.datas.map(item => {
            return {
              key: item.oid,
              label: item.company_name,
              value: item.oid
            }
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },

    // 获取BOM数据
    async getBomData(prooid) {
      const res = await fetch('post', '/pro/bom', { oid: prooid })
      if (res.status === 0) {
        this.bomData = res.data
      } else {
        this.$message.error(res.message)
      }
    },

    // 获取库存信息
    async getInventoryInformation(prooid) {
      const res = await fetch('post', '/whs/info', { prooid })
      if (res.status === 0) {
        this.oid = res.data.oid
        this.inventory.safe_count = res.data.safe_count
        this.inventory.store_count = res.data.store_count
      } else {
        this.$message.error(res.message)
      }
    },

    // 获取库存明细信息
    async getInventoryDetails(prooid) {
      const res = await fetch('post', '/whs/his/retrieve', { prooid, page_size: 9999 })
      if (res.status === 0) {
        this.inventoryDetails = res.data.datas
      } else {
        this.$message.error(res.message)
      }
    },

    // 获取应用项目数据
    async getApplicationProjectData(prooid) {
      const res = await fetch('post', '/prj/list/bypro', { prooid })
      if (res.status === 0) {
        this.projectData = res.data
      } else {
        this.$message.error(res.message)
      }
    },

    // 切换tab
    leftTabChange(key) {
      const map = {
        1: this.prooid,
        2: '',
        3: this.oid,
        4: this.projectData[0]?.oid || ''
      }

      this.sourceOid = map[key] || ''
    },

    // 切换项目
    toggleProjectHistory(record) {
      this.sourceOid = record.oid
    },
    // 关闭弹窗
    closeDialog() {
      this.$emit('update:showDialog', false)
      this.$emit('update:prooid', '')
      // this.$emit('update:oid', '')
      this.$emit('getPageData')
      this.leftTab = '1'
      this.bomData = []
      this.projectData = []
      this.inventory = { store_count: '', safe_count: '' }
    },
    // 更改树形图标
    expandIcon(props) {
      if (props.record.children.length === 0) return <span style="margin-right:19px"></span>
      if (props.expanded) {
        return (
            <a style={{width:'19px',display:'inline-block'}} class="table-icon" onClick={(e) => {
              props.onExpand(props.record, e);
            }}>
              <i class="iconfont icon-xiangxia expand-icon"></i>

              {" "}
            </a>
        )
      } else {
        return (
            <a style={{width:'19px',display:'inline-block'}} class="table-icon" onClick={(e) => {
              props.onExpand(props.record, e);
            }}>
              <i class="iconfont icon-xiangxia expand-icon open"></i>

              {" "}
            </a>
        )
      }
    }
  }
}
</script>

<style lang="less" scoped>
.modal-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #303030;

  > img {
    width: 18px;
    height: 18px;
    margin-right: 7px;
  }
}

.modal-body {
  .body-top {
    padding: 16px 20px;

    .title {
      margin-bottom: 10px;
    }

    .detail {
      display: flex;
      justify-content: flex-start;

      .detail-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        overflow: hidden;

        .category-img {
          width: 32px;
          height: 32px;
          background-color: #80B8F8;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:first-child, &:last-child {
          width: 150px;
        }

        &:nth-child(2), &:nth-child(3) {
          width: 130px;
        }

        .round {
          flex-shrink: 0;
          width: 32px;
          height: 32px;
          border-radius: 50%;

          &.blue {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #80b8f9;
            color: white;
            font-weight: 700;
          }
        }

        .right {
          width: calc(100% - 32px - 8px);
          margin-left: 8px;
          overflow: hidden;

          /deep/ .ant-select {
            .ant-select-selection-selected-value {
              font-size: 16px;
            }
          }

          .onefield {
            width: 100%;
          }

          .label {
            color: #909090;
            font-weight: 500;
            font-size: 12px;
            margin-top: 0;
          }
        }
      }
    }
  }

  .body-content {
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: minmax(0, 1fr) 330px;
    border-top: 1px solid #c2c9cf;
    height: 600px;

    .tag-record-list {
      flex: 1;
      overflow-y: auto;

      .tag-record-item {
        padding: 12px 21px;
        border-bottom: 1px solid #C2C9CF;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 12px;

        &:hover {
          background-color: #f9f9f9;
        }

        > * {
          margin-right: 8px;
        }
      }
    }

    > .left {
      width: 100%;
      border-right: 1px solid #ccd2d7;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .title {
        display: flex;

        .right-action {
          display: flex;
          align-items: center;
          margin-left: auto;
          color: #494949;
          font-size: 14px;
          font-weight: 400;
          cursor: pointer;

          img {
            max-height: 18px;
            max-width: 18px;
            margin-right: 5px;
          }

          i {
            margin-right: 5px;
          }
        }
      }

      .tab-item {
        flex: 1;
        overflow: hidden;
      }

      .active-2 {
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        /deep/ .ant-table-thead {
          tr {
            th {
              font-size: 14px;
              //background: none;
              border-right: none;
              padding-left: 10px;
              padding-right: 5px;

              &:first-child {
                padding-left: 20px;
              }
            }
          }
          th[key=node_name]{
            .ant-table-header-column{
              margin-left: 19px;
            }
          }
        }

        /deep/ .ant-table-tbody {
          tr {
            td {
              font-size: 12px;
              border-right: none;
              padding-left: 10px;
              padding-right: 5px;

              &:first-child {
                padding-left: 20px;
              }
            }
          }
        }

        /deep/ .ant-table-wrapper {
          .ant-table-tbody {
            .table-icon {
              color: #000;
            }
          }
        }
      }

      .active-3 {
        overflow-y: auto;

        .inventoryDetails {
          //padding: 0 21px;

          .item {
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;

            &::after {
              content: '';
              width: 100%;
              border-bottom: 1px solid #c2c9cf;
            }

            .top {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;
              padding: 0 21px;

              .left {
                display: flex;
                margin-right: 10px;

                .icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 25px;
                  height: 25px;

                  color: #fff;
                  border-radius: 50%;
                  margin-right: 5px;

                  &.out {
                    background-color: #FF0000;
                  }

                  &.enter {
                    background-color: #008001;
                  }
                }

                .name {
                  font-size: 16px;
                  font-weight: 500;
                  color: #000000;
                  margin-right: 12px;
                }

                .time {
                  font-size: 16px;
                  color: #939393;
                  margin-right: 12px;
                }

                .project {
                  width: 275px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 16px;
                  color: #939393;
                }
              }

              .right {
                flex: 1;
                text-align: right;
                font-weight: 700;
                font-size: 18px;

                &.out {
                  color: #FF0000;
                }

                &.enter {
                  color: #008001;
                }
              }
            }

            .bottom {
              display: flex;
              margin-bottom: 12px;
              padding: 0 21px;

              .bottom-item {
                padding: 0 4px;
                background-color: #EEEEEE;
                color: #939393;
                margin-right: 10px;
              }
            }
          }
        }
      }

      .active-4 {
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        /deep/ .ant-table-thead {
          tr {
            th {
              font-size: 14px;
              //background: none;
              border-right: none;
              padding-left: 10px;
              padding-right: 5px;

              &:first-child {
                padding-left: 20px;
              }
            }
          }
        }

        /deep/ .ant-table-tbody {
          tr {
            td {
              font-size: 12px;
              border-right: none;
              padding-left: 10px;
              padding-right: 5px;

              &:first-child {
                padding-left: 20px;
              }

              .status {
                border-radius: 20px;
                font-size: 12px;
                font-weight: 400;
                padding: 3px 8px;
                box-sizing: border-box;

                &.blue {
                  border: 1px solid #0088FF;
                  color: #0088FF;
                }

                &.yellow {
                  border: 1px solid #F0A100;
                  color: #F0A100;
                }

                &.green {
                  border: 1px solid #02D35F;
                  color: #02D35F;
                }

                &.gray {
                  border: 1px solid #909090;
                  color: #909090;
                }
              }
            }
          }
        }
      }

      > .ant-tabs {
        flex-shrink: 0;

        /deep/ .ant-tabs-bar {
          border-bottom: 1px solid #C2C9CF;
          margin-bottom: 0;

          .ant-tabs-tab {
            &:first-child {
              margin-left: 15px;
            }

            padding: 16px 19px;
            margin-right: 0;
          }
        }
      }

      .title {
        font-size: 16px;
        font-weight: 500;
        color: #303030;
        margin-top: 25px;

        &:first-child {
          margin-top: 25px;
        }

        margin-bottom: 15px;
        line-height: 1;
        padding: 0 21px;
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: #c2c9cf;
      }

      .form-list {
        margin-bottom: 18px;
        display: grid;
        grid-template-columns:repeat(2, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 10px;
        padding: 0 21px;

        .form-item {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #4d4d4d;
          min-height: 32px;

          /deep/ .ant-calendar-picker-input {
            color: #4d4d4d;
          }

          /deep/ .ant-select-selection-selected-value {
            font-size: 14px;
          }

          /deep/ .ant-select-search__field {
            font-size: 14px;
          }
        }

        .label {
          width: 120px;
          flex-shrink: 0;
          color: #303030;
          font-size: 14px;
        }
      }
    }

    .right {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .tab {
        display: flex;
        width: 100%;
        flex: 1;
        flex-direction: column;
        overflow: hidden;

        .y-scroll {
          flex: 1;
          overflow-y: auto;

          .active-1 {
          }
        }
      }

      > .title {
        height: 45px;
        line-height: 50px;
        padding: 0 13px;
        font-size: 16px;
        font-weight: 500;
        color: #303030;
      }

      > .tab {
        padding: 0 13px;

        .ant-tabs {
          /deep/ .ant-tabs-bar {
            margin-bottom: 0;
          }
        }
      }

      .comment-input {
        margin-top: auto;
        display: flex;
        height: 50px;
        align-items: center;
        border-top: 1px solid #CACACA;

        input {
          flex: 1;
          height: 100%;
          border: none;
          outline: none;
          padding-left: 10px;
          font-size: 14px;
          color: #909090;
        }

        .send {
          padding: 0 20px;
          cursor: pointer;
          color: #333;
        }
      }
    }
  }
}
</style>
