<template>
  <a-auto-complete
      v-bind="$attrs"
      :class="{invalid: !freeInput && [undefined, null, ''].indexOf(value) !== -1 , 'edp-input': popperClass === 'edp-input'}"
      :popper-class="`auto-input ${popperClass}`"
      v-model="nativeLabel"
      @search="inputEvent"
      @blur="blurEvent"
      label-in-value
      @select="selectedEvent"
  >
    <template slot="dataSource">
      <a-select-option v-for="item in dataSource" :key="item?.oid" :data="item">{{item[listLabelKey]}}</a-select-option>
    </template>
  </a-auto-complete>
</template>

<script>
import { fetch } from '../utils/request'

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    popperClass: String,
    // label的key
    labelKey: String,
    // value值的key
    valueKey: String,
    // 接口
    url: String,
    // 查询详情接口为了优化智能感知速度（非必传）
    infoUrl: String,
    // label
    label: {
      type: [String, Boolean],
      default: () => false
    },
    // 列表中的labelKey
    listLabelKey: {
      type: String,
      default() {
        return this.labelKey
      }
    },
    // v-model绑定的值
    value: {
      require: false
    },
    // 自由输入
    freeInput: {
      type: Boolean,
      default: () => false
    },
    // 请求url传入的关键词字段名称
    keyWordKey: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Boolean,
      default: () => false
    },
    // 每次查询数据额外携带的query
    query: {
      type: Object,
      default: () => {}
    },
    page: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Boolean,
      default: () => true
    },
    // 传入index用于列表智能感知带出去的值在哪一行
    idx : {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Object | Number
    }
  },
  data () {
    return {
      loading: false,
      nativeLabel: '',
      tempValue: "",
      tempLabel: "",
      dataSource: []
    }
  },
  model: {
    props: 'value',
    event: 'setValue'
  },
  watch: {
    label: {
      immediate: true,
      // 监控如果父组件传来的label改变了则同时改变当前组件输入框内的label
      handler(newLabel, oldLabel) {
        // 如果label不是布尔型
        if (typeof newLabel !== "boolean") {
          // 如果新旧不一且新label和临时label不一样
          // 设置tempLabel和tempValue的作用是区分是外部更改还是内部更改
          // 内部对label\value赋值前都会手动将对应的tempLabel\tempValue修改成赋值结果
          // 这样在需要监听label、value的地方就可以判断此次更改是由内部更改还是外部更改
          if (newLabel !== oldLabel && newLabel !== this.tempLabel) {
            this.nativeLabel = newLabel
            // 这里需要修改是因为 已经检测并触发到label修改了 将tempLabel同步至最新状态以保证下一次更改时检测结果准确性
            this.setModel("label", newLabel,  true)
          }
        }
      }
    },
    value: {
      immediate: true,
      // 监控如果父组件传来的value改变了则清空当前组件输入框内的label
      handler(v) {
        if (v !== this.tempValue) {
          this.nativeLabel = this.label || v || ""
        }
      }
    }
  },
  methods: {
    validKey(row) {
      // 这里只是输出错误信息 不会阻止程序向下运行，但是因为找不到对应键的值 代码可能不会按照预期运行
      let keys = Object.keys(row)
      if (!keys.includes(this.labelKey)) {
        console.error(`[AutoInput] labelKey: "${this.labelKey}" 在当前点击行数据中不存在, 请检查填写是否正确`)
      }
      if (!keys.includes(this.valueKey)) {
        console.error(`[AutoInput] valueKey: "${this.valueKey}" 在当前点击行数据中不存在, 请检查填写是否正确`)
      }
    },
    setModel(type, value, changeTempOnly = false) {
      switch (type) {
        case "value":
          this.tempValue = value
          !changeTempOnly && this.$emit("setValue", value)
          break
        case "label":
          this.tempLabel = value
          !changeTempOnly && this.$emit("update:label", value)
      }
    },
    remoteMethod (keyword, cb) {
      if (keyword === undefined || keyword === null || keyword.length === 0) {
        cb([])
        return
      }
      let _this = this
      let query = {
        page_index: 1,
        page_size: 30,
        // company_oid: localStorage.getItem('company_oid'),
        ...this.query
      }
      if (typeof this.keyWordKey !== "string") {
        query.keywords = keyword
      } else {
        query[this.keyWordKey] = keyword
      }
      fetch('POST', _this.url, query).then((res) => {
        const vCb = (list) => {
          cb(Array.isArray(list) ? list : [])
        }
        if (res.data != null) {
          if (this.page === false) {
            vCb(res.data)
          } else if (this.page === true) {
            vCb(res.data.datas || res.data.datalist)
          } else {
            vCb(res.data[this.page] || [])
          }
        } else {
          cb([])
        }
      })
    },
    blurEvent() {
      setTimeout(() => {
        if (this.freeInput) {
          this.$emit("blur")
        } else if ([undefined, null, ''].includes(this.value)) {
          this.nativeLabel = ""
          this.setModel("label", "")
        }
      }, 10)
    },
    async selectedEvent(row, optian) {
      // 为了优化智能感知速度 分成了两个接口来查询
      let data = {};
      if (this.infoUrl) {
        let res = await fetch('POST', this.infoUrl, {oid: optian.data.attrs.data.oid})
        if (res.status === 0 && res.data != null) data = res.data.datas[0]
      } else {
        data = optian.data.attrs.data
      }
      this.validKey(data)
      let value = data[this.valueKey]
      let label = data[this.labelKey]
      this.nativeLabel = label
      this.setModel("value", value)
      this.setModel("label", label)
      this.$emit('change', value, data, this.idx)
    },
    inputEvent(e) {
      // 如果允许自由输入则直接更改label
      const that = this
      this.remoteMethod(e, function (val) {
        that.dataSource = val
      })
      // 文本被输入 清空父组件的v-model变量的值
      if (this.value !== "" && this.value !== undefined) {
        this.setModel("value", "")
      }
      if (this.freeInput) {
        this.setModel("label", e)
      } else {
        // 文本被输入 清空父组件的label变量的值
        if (this.label !== "" && this.label !== undefined) {
          this.setModel("label", "")
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.invalid /deep/ .el-input__inner{
  color: #b2b2b2;
}
/deep/ .el-input__inner {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  font-size: 14px;
  padding: 0 12px;
  &::placeholder {
    color: #979797;
  }
  &::-webkit-input-placeholder {
    color: #979797;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #979797;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #979797;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #979797;
  }
}

/deep/ .el-autocomplete {
  width: 100%;
}

.edp-input {
  width: var(--width, 200px);

  /deep/ .ant-input{
    border-color: rgba(0,0,0,0);
    color: #303030;
    font-size: 14px;
    height: 32px;
    font-weight: var(--fontWeight, 400);
    padding: 0;

    &:hover, &:focus {
      border-color: var(--primary-color);
      padding: 0 8px;
    }
  }
}
</style>
