<template>
  <div
    :class="`edp-input edp-input-size-${size} ${$slots.default ? 'has-slot' : ''} ${focused || hover ? 'focus' : ''} ${number ? 'number' : ''}`"
    @mouseover="onMouseover"
    @mouseout="onMouseout"
  >
    <a-input
      ref="input"
      v-if="!number"
      v-bind="$attrs"
      :class="`edp-input-text`"
      v-model="tempValue"
      @focus="onFocus"
      @blur="changeEvent"
    >
    </a-input>

    <a-input-number
      ref="input"
      v-else
      v-bind="$attrs"
      class="edp-input-number"
      v-model="tempValue"
      @blur="changeEvent"
      :max="max"
      @focus="onFocus"
      :precision="precision">
    </a-input-number>
    <div class="default-view">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import edpFormMixins from "@/components/e-form/edpFormMixins.js";

export default {
  name: "edp-input",
  mixins: [edpFormMixins],
  props: {
    number: {
      type: Boolean,
      default: () => false
    },
    precision: {
      type: Number,
      default: () => 2
    },
    max: {
      type: Number,
    },
    size: {
      type: String,
    }
  },
  data() {
    return {
      focused: false,
      hover: false
    }
  },
  methods: {
    onMouseover() {
      this.hover = true
    },
    onMouseout() {
      this.hover = false
    },
    onFocus() {
      this.focused = true
    },
    onBlur() {
      this.focused = false
    },
    changeEvent() {
      this.onBlur()
      if (this.disabledAutoUpdate) {
        return
      }
      if (this.isEq(this.tempValue, this.preValue)) {
        this.$emit('blur')
        return
      }
      this.updateField()
    }
  }
}
</script>

<style lang="less" scoped>
.hide {
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.edp-input {
  width: var(--width, 200px);
  height: var(--height);
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  .default-view { display: none;}
  &.has-slot {
    &:not(.focus) {
      .edp-input-text:extend(.hide) {}
      .edp-input-number:extend(.hide) {}
    }
    &.focus {
      .default-view:extend(.hide) {
        padding-left: 8px;
      }
    }
    .default-view {
      width: 100%;
      height: 100%;
      display: flex;
      transition: all 0.3s;
      align-items: center;
      color: #303030;
      font-size: var(--fontSize);
    }
  }
  &-size-mini {
    --height: 24px;
    .edp-input-number {
      /deep/ .ant-input-number-handler-wrap {
        display: none;
      }
    }
  }
  &-size-big {
    --width: 100%;
    --fontSize: 18px;
    --height: 32px;
  }
  &-size-middle {
    --fontSize: 14px;
    --height: 32px;
  }
  .edp-input-text {
    width: 100%;
    height: 100%;
    border-color: rgba(0,0,0,0);
    color: #303030;
    font-weight: var(--fontWeight, 400);
    padding: 0;
    font-size: var(--fontSize);
  }
  &.focus .edp-input-text {
    border-color: var(--primary-color);
    padding: 0 8px;
  }
  .edp-input-number {
    width: var(--width, 200px);
    border-color: rgba(0,0,0,0);
    height: var(--height, 32px);
    /deep/ .ant-input-number-input-wrap {
      height: 100%;
      input {
        font-weight: var(--fontWeight, 400);
        color: #303030;
        padding-left: 0;
        height: 100%;
      }
    }
    &:hover, &.ant-input-number-focused {
      border-color: var(--primary-color);
      /deep/ input{
        padding-left: 8px;
      }
    }
  }
}
</style>
