<template>
  <a-textarea :auto-size="{ minRows }" v-bind="$attrs" v-model="tempValue" @blur="changeEvent">

  </a-textarea>
</template>

<script>
import edpFormMixins from "@/components/e-form/edpFormMixins.js";

export default {
  mixins: [edpFormMixins],
  props: {
    minRows: {
      type: Number,
      default: () => 3
    }
  },
  methods: {

  }
}
</script>