<template>
  <a-tree-select
      v-bind="$attrs"
      v-model="newValue"
      style="width: 100%"
      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
      :tree-data="treeData"
      :replaceFields="{children:'children', title:'cat_name', key:'oid', value: 'cat_code'}"
      tree-default-expand-all
      @change="selectValueChange"
  >
  </a-tree-select>
</template>

<script>
import { fetch } from "@/utils/request";

export default {
  name: "category-tree-select",
  props: {
    value: {
      type: String,
      default: ''
    },
    oid: {
      type: String,
      default: ''
    },
    proOid: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      treeData: [],
      newValue: '',
      oldValue: ''
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.newValue = newValue
      }
    },
    newValue: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.newValue = newValue
        this.oldValue = oldValue
      }
    }
  },
  created() {
    this.getProductCategory()
  },
  methods: {
    // 获取产品类型数据
    async getProductCategory() {
      const res = await fetch('post', '/cpy/cat/getall', { cat_type: 'product_category', page_size: 9999 })
      if (res.status === 0) {
        this.treeData = res.data.categories
      } else {
        this.$message.error(res.message)
      }
    },
    selectValueChange() {
      this.$nextTick(() => {
        let params = {
          field_id: 'pro_core',
          field_name: 'product_category',
          field_value: this.newValue,
          oid: this.oid,
          prooid: this.proOid,
          old_value: this.findObjectByCode(this.treeData, this.oldValue).cat_name,
          new_value: this.findObjectByCode(this.treeData, this.newValue).cat_name
        }
        return fetch('post', '/pro/update/onefield', params).then((res) => {
          if (res.status === 0) {
            this.$message.success('修改成功！')
            this.$emit('change')
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    findObjectByCode(arr, targetCode) {
      for (const item of arr) {
        if (item.cat_code === targetCode) {
          return item
        }
        if (item.children) {
          const result = this.findObjectByCode(item.children, targetCode)
          if (result) {
            return result
          }
        }
      }
      return null
    }
  },
}
</script>

<style lang="less" scoped>
.ant-select:not(:hover) {
  line-height: 22px;
  height: 22px;

  /deep/ .ant-select-selection {
    color: #303030;
    border-color: rgba(0, 0, 0, 0);
    height: 22px;
    line-height: 22px;

    .ant-select-selection__rendered {
      margin: 0;
      line-height: 22px;

      .ant-select-selection-selected-value {
        line-height: 22px;
      }
    }


    .ant-select-arrow {
      display: none;
    }
  }
}

.ant-select {
  height: 22px;
  line-height: 22px;

  /deep/ .ant-select-selection {
    color: #303030;
    border-color: var(--primary-color);
    height: 22px;
    line-height: 22px;

    .ant-select-selection__rendered {
      line-height: 22px;
      transition: .3s margin;

      .ant-select-selection-selected-value {
        line-height: 22px;
      }
    }
  }
}
</style>
